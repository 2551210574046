export interface IWhiteLabel {
    value: string,
    title: string,
    url: string
}

export class WhiteLabel implements IWhiteLabel {
    value: string;
    title: string;
    url: string;

    constructor(value: string, title: string, url: string) {
        this.value = value;
        this.title = title;
        this.url = url;
    }
}

export interface WhiteLabelList {
    default: string,
    whitelabelPath: string,
    values: WhiteLabel[]
}

export const WHITELABELS: WhiteLabelList = {
    default: 'kinesso',
    whitelabelPath: '/white-label/white-label.js',
    values: [
        new WhiteLabel('bgenius','bGenius','https://mie.bgenius.com'),
        new WhiteLabel('interact','Interact','https://interact.interpublic.com'),
        new WhiteLabel('kinesso','Kinesso','https://mie.kinesso.com'),
        new WhiteLabel('matterkind','Matterkind','https://mie.matterkind.com'),
        new WhiteLabel('bpn','BPN','https://bpn.kinesso.com'),
        new WhiteLabel('um','UM','https://futureproof.umww.com'),
        new WhiteLabel('fullcolormedia','UM Fullcolor Media','https://fullcolormedia.umww.com'),
        new WhiteLabel('initiative','Initiative','https://mie.initiative.com'),
        new WhiteLabel('interpublic','Interpublic Group','https://mie.interpublic.com'),
        new WhiteLabel('orion','Orion Worldwide','https://mie.orionworldwide.com'),
        new WhiteLabel('acxiom','Acxiom','https://mie.acxiom-tech.com'),
        new WhiteLabel('mediaexperts', 'Media Experts','https://businessiq.mediaexperts.com'),
        new WhiteLabel('mediabrands','MediaBrands','https://mie.ipgmediabrands.com'),
        new WhiteLabel('rapport','Rapport','https://mie.rapportww.com'),
        new WhiteLabel('reprise','Reprise','https://mie.reprise-tech.com'),
        new WhiteLabel('rufus','Rufus','https://mie.rufusww.com'),
        new WhiteLabel('thrive-bae','Thrive','https://thrive-bae.umww.com'),
        new WhiteLabel('bolt','UM BOLT','https://bolt.umww.com'),
        new WhiteLabel('j3','J3','https://mie.umj3.com'),
        new WhiteLabel('healix','Healix','https://huddle.healixglobal.com'),
        new WhiteLabel('kanvas','Kanvas','https://kanvas.kinesso.com'),
        new WhiteLabel('mediahub','MediaHub','https://mie.mediahubww.com'),
        new WhiteLabel('citi','Citi','https://citi.acxiom-tech.com'),
        new WhiteLabel('chase','Chase','https://chase.acxiom-tech.com'),
        new WhiteLabel('red-thread','Red Thread','https://redthreadmie.interpublic.com'),
        new WhiteLabel('futureproofiq','Futureproof IQ','https://futureproofiq.umww.com'),
        new WhiteLabel('umiq','UM IQ','https://umiq.futureproof.umww.com'),
        // new WhiteLabel('jbl','JBL','https://prod-jbl.kinesso.global'),
        new WhiteLabel('fusion','Fusion','https://fusionmie.interpublic.com'),
        new WhiteLabel('fusionmie','Fusion MIE','https://fusionmie.kinesso.global')
    ],
};
